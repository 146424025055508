import React from "react";
import { Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/home/Home";
import Login from "./containers/auth/login/Login";
import Notes from "./containers/notes/Notes";
// import Signup from "./containers/auth/Signup";
// import NewNote from "./containers/notes/NewNote";
import Dashboard from "./containers/dashboard/Dashboard";
// import Settings from "./containers/settings/Settings";
import NotFound from "./containers/error/NotFound";

import HomePage from "./containers/HomePage";
import PhotographyPage from "./containers/photography/PhotographyPage";
import ResumePage from "./containers/resume/ResumePage";
import ProgrammingPage from "./containers/programming/ProgrammingPage";
import TravelsPage from "./containers/travels/TravelsPage";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      {/* <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute> */}

      <AuthenticatedRoute exact path="/home" component={HomePage} />
      <AuthenticatedRoute
        exact
        path="/Photography"
        component={PhotographyPage}
      />
      <AuthenticatedRoute exact path="/Resume" component={ResumePage} />
      <AuthenticatedRoute
        exact
        path="/Programming"
        component={ProgrammingPage}
      />
      <AuthenticatedRoute exact path="/Travels" component={TravelsPage} />

      <AuthenticatedRoute exact path="/notes/:id">
        <Notes />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/dashboard">
        <Dashboard />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
