const dev = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-west-2",
    BUCKET: "josiahwlee-uploads-dev-s3bucket-1ioq2zv6pscyg",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://yl7h7yduq2.execute-api.us-west-2.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_5KH8FoswI",
    APP_CLIENT_ID: "2m2fqm1l53q4no0t78jht2t2q0",
    IDENTITY_POOL_ID: "us-west-2:9d1b5dfc-3c76-4d1d-abb5-b2011b9b7748",
  },
};

// npx aws-api-gateway-cli-test \
// --username='josiahwlee@gmail.com' \
// --password='Denisse9!' \
// --user-pool-id='us-west-2_5KH8FoswI' \
// --app-client-id='2m2fqm1l53q4no0t78jht2t2q0' \
// --cognito-region='us-west-2' \
// --identity-pool-id='us-west-2:9d1b5dfc-3c76-4d1d-abb5-b2011b9b7748' \
// --invoke-url='https://yl7h7yduq2.execute-api.us-west-2.amazonaws.com/dev' \
// --api-gateway-region='us-west-2' \
// --path-template='/notes' \
// --method='POST' \
// --body='{"content":"hello world","attachment":"hello.jpg"}'
// AttachmentsBucketName: josiahwlee-uploads-dev-s3bucket-1ioq2zv6pscyg
// AttachmentsBucketArn: arn:aws:s3:::josiahwlee-uploads-dev-s3bucket-1ioq2zv6pscyg

// https://yl7h7yduq2.execute-api.us-west-2.amazonaws.com/dev/

// UserPoolClientId: 2m2fqm1l53q4no0t78jht2t2q0
// UserPoolId: us-west-2_5KH8FoswI
// CognitoAuthRole: josiahwlee-auth-dev-CognitoAuthRole-15YB5IDR62GEZ
// IdentityPoolId: us-west-2:9d1b5dfc-3c76-4d1d-abb5-b2011b9b7748

const prod = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-west-2",
    BUCKET: "josiahwlee-uploads-prod-s3bucket-1c4p8e0gsbu71",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://le9yj9b6u9.execute-api.us-west-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_xWfaNSJ04",
    APP_CLIENT_ID: "29341utcmtas6ir5st8bdbpl87",
    IDENTITY_POOL_ID: "us-west-2:70def9bc-5661-43e2-8dfa-70334a27aed6",
  },
};

// AttachmentsBucketName: josiahwlee-uploads-prod-s3bucket-1c4p8e0gsbu71

// UserPoolClientId: 29341utcmtas6ir5st8bdbpl87
// UserPoolId: us-west-2_xWfaNSJ04
// CognitoAuthRole: josiahwlee-auth-prod-CognitoAuthRole-QQWADWJDEIXE
// IdentityPoolId: us-west-2:70def9bc-5661-43e2-8dfa-70334a27aed6

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

const temp = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};

export default temp;
