import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {ProgressBar, Button} from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { onError } from "../../../libs/errorLib";
import './Duolingo.css';


export default function Duolingo(props) {
    const [duo, setDuo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    function getDuo() {
        const {user, password} = props;
        return API.get("notes", "/getDuoUser", { 
            queryStringParameters: { 
                user: user,
                pass: password
            }
        });
    }

    // function postDuo() {
    //     const {user} = props;
    //     return API.post("notes", "/duolingo", { 
    //         body: { 
    //             user: user,
    //         }
    //     });
    // }

    // function addAccountDuo() {
    //     const {user, pass} = props;
    //     return API.post("notes", "/accounts/duo", { 
    //         body: { 
    //             user: user,
    //             pass: pass
    //         }
    //     });
    // }

    async function refresh(){
        setIsLoading(true);
    }
    
    useEffect(() => {
        async function onLoad() {
            try {
                const duo = await getDuo();
                // console.log(duo);
                setDuo(duo);
            } catch (e) {
                onError(e);
            }
            setIsLoading(false);
        }
        onLoad();
        // eslint-disable-next-line
    }, [isLoading]);

    const renderDuolingoDash = () => {
        const {complete_skills, total_crowns, max_skills, max_crowns, xp_today} = duo;
        const {streak, level} = duo.language_data[Object.keys(duo.language_data)[0]];
        const skillProgress = Math.round( complete_skills/max_skills*10000 )/100;
        const crownProgress = Math.round( total_crowns/max_crowns*10000 )/100;
        const {name, skillGoal, crownGoal} = props;
        const crownDays = Math.ceil((max_crowns-total_crowns)/crownGoal);
        const skillDays = Math.ceil((max_skills-complete_skills)/skillGoal);
        return (
            <div className="Duolingo">
                <h2>{`${name}'s Duolingo ${duo.learning_language_string}`}<span className="titlesubtext">{(new Date()).toDateString()}</span></h2>
                <Button onClick={refresh}>Refresh Info</Button>
                <h4>{`Level ${level} - ${streak} day streak - ${xp_today} xp today`}</h4>
                <p>{`At ${crownGoal} ${crownGoal===1?'crown':'crowns'} a day you will finish in ${crownDays} ${crownDays===1?'day':'days'}.`}</p>
                <p className="subtext">{`${total_crowns} / ${max_crowns} crowns completed - ${crownProgress}%`}</p>
                <ProgressBar striped bsStyle="info" now={crownProgress} label={`${crownProgress}%`}/>
                <p>{`At ${skillGoal} ${skillGoal===1?'skill':'skills'} a day you will finish in ${skillDays} ${skillDays===1?'day':'days'}.`}</p>
                <p className="subtext">{`${complete_skills} / ${max_skills} skills completed - ${skillProgress}%`}</p>
                <ProgressBar striped bsStyle="info" now={skillProgress} label={`${skillProgress}%`}/>
                {/* <Button onClick={postDuo}>Post Duo</Button> */}
                {/* <Button onClick={addAccountDuo}>addAccount</Button> */}
            </div>
        );
    }

    return (
        isLoading ? <Loader visible={isLoading} type="TailSpin" color="#00BFFF" height={80} width={80} /> :
        renderDuolingoDash()
    );
}
