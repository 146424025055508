import React from "react";
import { Grid, Row, Col } from "react-bootstrap";

import Duolingo from "./components/Duolingo";
// import Robinhood from './components/Robinhood';
import "./Dashboard.css";

export default function Dashboard() {
  return (
    <div className="Dashboard">
      <p>Dash</p>
      <Grid>
        <Row>
          <Col sm={6}>
            <Duolingo
              user="jwlee64"
              name="Josiah"
              password="trygod"
              crownGoal={12}
              skillGoal={3}
            />
            {/* <Robinhood user='jwlee64'/> */}
          </Col>
          <Col sm={6}>
            <Duolingo
              user="DenEsp98"
              name="Denisse"
              password="Denisse9"
              crownGoal={5}
              skillGoal={1}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
